<template>
  <div class="delivery-show pb-4">
    <is-loading v-if="isLoading" />
    <div v-else class="row">
      <div class="col-12 mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/orders">Orders</router-link></li>
            <li class="breadcrumb-item"><router-link :to="`/orders/${$route.params.orderId}`">View order</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Delivery</li>
          </ol>
        </nav>
      </div>
      <div class="col-lg-12">
        <div class="mb-3">
          <div class="bg-white p-2 d-flex align-items-center">
              <img style="width:50px;height:50px;" class="rounded-circle me-2" :src="absoluteUrl(delivery.worker.image)"/> 
              <span class="text-capitalize"><span class="h6 mb-0">{{delivery.worker.username}}</span> <br class="d-sm-none"> (designer)</span>
          </div>
        </div>
        <div class="mb-3" v-if="canTakeAction" style="line-height:2.5">
          <b-button @click="updateDelivery('FINALIST')" class="me-3" size="sm" variant="outline-primary">
            <i class="mdi mdi-check"></i> Select as finalist</b-button>
          <b-button @click="updateDelivery('ELIMINATED')" class="me-3" size="sm" variant="outline-primary">
            <i class="mdi mdi-close"></i> Eliminate designer</b-button>
          <b-button @click="updateDelivery('BLOCKED')" class="me-3" size="sm" variant="outline-primary">
              <i class="mdi mdi-face"></i> Block Designer</b-button>
        </div>
        <div class="mb-3" v-if="canChooseWinner">
            <b-button @click="updateDelivery('WINNER')" class="me-3" size="sm" variant="outline-primary">
                <i class="mdi mdi-check"></i> Select as winner</b-button>
        </div>
        <div class="">
            <delivery-files :files="delivery.files" />
        </div>
        <div class="text-muted border-bottom pb-2 my-2 font-weight-bold d-flex justify-content-between">
            <span>{{$filters.date_ago(delivery.created_at)}}</span>
        </div>
        <div v-if="canShowReviewForm">
          <order-review-form  :delivery-id="delivery.id"/>
        </div>
        <div v-if="delivery.is_reviewed">
          <ul class="list-inline mb-0">
            <li class="list-inline-item me-1" v-for="star in parseInt(delivery.review.rating)" :key="'0-'+star">
                <img :src="absoluteUrl('/images/svg/star.svg')" alt="Review rating" width="12"></li>
            <li class="list-inline-item me-1" v-for="nostar in  ((delivery.review.rating % 1 == 0 ? 5 : 4) - parseInt(delivery.review.rating))" :key="nostar">
                <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12"></li>
          </ul>
          <p class="mb-0 text-muted">{{delivery.review.content}}</p>
        </div>
      </div>
      <div class="col-lg-10">
        <delivery-messages />
      </div>
    </div>
  </div>
</template>
<script>

import OrderReviewForm from "@/components/order/OrderReviewForm.vue"
import DeliveryMessages from "@/components/order/DeliveryMessages.vue"
import DeliveryFiles from "@/components/order/DeliveryFiles.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        OrderReviewForm,
        DeliveryMessages,
        DeliveryFiles,
        IsLoading,
    },
    data(){
      return {
        isLoading: true,
      }
    },
    watch: {
    '$route.params.deliveryId': function (id){
        if(id) this.fetchDelivery()
      },
    },
    computed: {
      delivery(){
        return this.$store.state.orderList.delivery
      },
      order(){
        return this.delivery.order
      },
      canTakeAction(){
        return !["FINALIST","ELIMINATED","BLOCKED"].includes(this.delivery.status) && this.order.stage != 'finished'
      },
      canChooseWinner(){
        return ["FINALIST"].includes(this.delivery.status) && this.order.stage != 'finished'
      },
      canShowReviewForm(){
        return !this.delivery.is_reviewed && this.delivery.status == "WINNER"
      }
    },
    methods:{
      updateDelivery(action){
        const formData = new FormData()
        formData.append('data', JSON.stringify({status: action}))
        Swal.fire({
          title: "Are you sure?",
          text: `You are about to ${action} this desiger!`,
          reverseButtons:true,
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          cancelButtonColor: "#323a46",
          confirmButtonText: "Proceed!",
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.post(`/deliveries/${this.delivery.id}/update`,formData)
              .then((response) => {
              this.$store.dispatch("changeLoaderValue")
              if(response.data.success){
                this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data)
              }
            })
          }
        });
      },
      fetchDelivery(){
        this.$http.get(`/deliveries/${this.$route.params.deliveryId}/show`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.$store.commit("orderList/SET_DELIVERY", response.data.data)
          }else{
            this.$router.push({path: `/orders/${this.$route.params.orderId}`})
          }
        })
      }
    },
    created(){
      this.fetchDelivery()
    }
}
</script>
