<template>
  <div class="order-brief">
     <package-features v-if="order.product_id" :item="order.package"
     :attributes="order.attributes" class="mb-4" />

     <plan-features v-if="order.task_id" :item="order.plan"
     :attributes="order.attributes" class="mb-4" />

    <addons v-if="order.product_id" :addons="cart.addons" class="mb-4" />
    
    <order-requirements :requirements="cart.requirements" />
  </div>
</template>

<script>

import Addons from "./Addons.vue"
import PackageFeatures from "./PackageFeatures.vue"
import PlanFeatures from "./PlanFeatures.vue"
import OrderRequirements from "./OrderRequirements.vue"

export default {
  components:{
    Addons,
    PackageFeatures,
    PlanFeatures,
    OrderRequirements
  },
  computed:{
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
  },
}

</script>

