<template>
<div class="order-notes">
  <h5>Do you have more information to send across to the designers?</h5>
  <p class="text-success">Write extra note and save. You can provide more description to your project.</p>
  <div class="ckeditor-classic">
    <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
  </div>
  <button @click="updateNote()" class="mt-3 mb-2 px-lg-5 btn btn-dark">Save</button>
  <p class=""><small><i> Note: The saved note shall be available to all the designers contesting in this project </i></small></p>
</div>
</template>

<script>

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components:{
    ckeditor: CKEditor.component
  },
  data() {
    return {
      editorData: '',
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
          ]
        }
      }
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
  },
  methods: {
    updateNote(){
      let formData = new FormData()
      formData.append('data', JSON.stringify({description: this.editorData}))
      this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/orders/${this.order.id}/save-note`, formData)
        .then(response => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.dispatch("orderList/fetchOrder",this.order.id)
          }
      })
    }
  },
  mounted(){
    this.editorData = this.order.description || ""
  }
}
</script>

<style>
.order-notes .ck-editor__editable_inline {
  min-height: 200px;
}
</style>