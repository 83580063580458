

<template>
<div>
  <h5 class="mb-0 text-primary">Features</h5>
  <hr class="mt-1"/>
  <ul class="row list-unstyled">
    <template v-for="(attribute, index) in attributes">
      <li class="col-sm-6 mb-1 col-lg-4" v-if="attribute.type == 'check' && supports[attribute.id] == 'supported'"  :key="index">
        <span>✔️</span> <span>{{attribute.name}} </span>
      </li>
      <li class="col-sm-6 mb-1 col-lg-4" v-if="attribute.type == 'input' && supports[attribute.id]"  :key="index">
        <span>✔️</span>
        <span> {{supports[attribute.id]}} {{attribute.name}} </span>
      </li>
    </template>
  </ul>
</div>
</template>

<script>
export default {
    name: "plan-features",
    props: {
      item: {
        type: Object,
        required: true,
      },
      attributes: {
        type: Object,
        required: true,
      },
    },
    computed:{
      supports(){
        return this.item.features || {};
      }
    }
}
</script>
