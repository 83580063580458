
<template>
  <div class="row gy-2">
    <div class="col-12">
        <b-tabs active-tab-class="active" nav-class="bg-white shadow nav-tabs-custom nav-primary mb-3">
            <b-tab title="My Team" active>
                <h4 class="mt-4 mb-3">My Team</h4>
                <div class="row gy-3">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body text-center">
                                <router-link class="avatar border border-white avatar-xl avatar-circle avatar-uploader" to="/settings/profile">
                                    <img id="editAvatarImgModal" class="avatar-img" :src="absoluteUrl(activeUser.image)" alt="Image Description">
                                    <span class="avatar-uploader-trigger">
                                        <i class="ri-edit-2-fill avatar-uploader-icon shadow-sm"></i>
                                    </span>
                                </router-link>
                                <h6 class="mt-3 mb-0">{{activeUser.first_name}} {{activeUser.last_name}}</h6>
                                <hr>
                                <p class="mb-2">{{activeUser.email}}</p>
                                <span class="badge bg-primary"> {{activeUser.role}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$store.state.config.appName">
            </b-tab>
        </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            data:{
     
            }
        }
    },
     computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
     }
}

</script>

<style lang="scss">

</style>
