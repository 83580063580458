<template>
  <div>
    <div class="conversation-text">
      <div class="ctext-wrap">
        <div class="mb-1">
          <div v-html="preText(message.content)"></div>
          <span class="text-muted font-10">{{$filters.date_ago(message.created_at) }}</span>
        </div>
        <message-files v-if="message.files && message.files.length"
        :files="message.files" @showImg="showImg($event)" />
        <div class="pt-1 mt-1 border-top">
          <slot name="actions"></slot>
        </div>
        <div v-if="message.replyer_id" class="pt-2">
          <h6 class="my-0 text-sm">
            {{message.replyer_id == activeUser.id ? 'You' : 'Designer'}} replied
          </h6>
          <div>
            <div v-html="preText(message.reply)"></div>
            <small class="text-muted">{{$filters.date_ago(message.replied_at)}}</small>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import MessageFiles from "./MessageFiles.vue"

export default {
  components:{
      MessageFiles
  },
  props:{
      buyer: {
        required: true,
        type:Object
      },
      message: {
        required: true,
        type: Object
      },
      index: {
        required: true,
        type:Number
      },
  },
  computed: {
    activeUser() {
      return this.$store.state.activeUser
    },
  },
  methods:{
    showImg(payload){
      this.$emit("showImg", payload)
    }
  }
}
</script>
