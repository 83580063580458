<template>
    <div class="task-launch py-4">
        <div v-if="!isLoading" class="mb-5">
            <div v-if="requirements.length">
                <div class="row mb-4 justify-content-center">
                    <div class="col-md-10 text-center">
                        <h3>Your Project Details</h3>
                        <p>Give us the brief to help you with the task</p>
                    </div>
                </div>
                <div id="add-requirement" class="row justify-content-center align-items-center">
                    <div class="col-md-10">
                        <div v-for="(requirement, rIndex) in requirements" :key="rIndex">
                            <div v-if="requirement.type == 'pro_input'" :id="`iReqT${requirement.id}`"
                                    class="row pro_input gy-2 mb-5 mb-md-8 justify-content-center">
                                <div class="col-lg-4">
                                    <h5 class="mb-1">
                                        {{requirement.title}} <span v-if="!requirement.required"  class="text-muted small">(Optional)</span>
                                    </h5>
                                    <small>{{requirement.description}}</small>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" v-model="data[requirement.id]" class="form-control"
                                    :placeholder="requirement.name" :name="`${requirement.id}_pro_input`">
                                </div>
                            </div>
                            <div v-if="requirement.type == 'pro_textarea'" :id="`iReqT${requirement.id}`" 
                                class="row pro_textarea gy-2 mb-5 mb-md-8 justify-content-center">
                                <div class="col-lg-4">
                                    <h5 class="mb-1">
                                        {{requirement.title}} <span v-if="!requirement.required"  class="text-muted small">(Optional)</span>
                                    </h5>
                                    <small>{{requirement.description}}</small>
                                </div>
                                <div class="col-lg-8">
                                    <textarea v-model="data[requirement.id]" :placeholder="requirement.name"
                                    class="form-control" :name="`${requirement.id}_pro_textarea`" rows="3"></textarea>
                                </div>
                            </div>
                            <div v-if="requirement.type == 'pro_select'" :id="`iReqT${requirement.id}`"
                                class="row pro_select gy-2 mb-5 mb-md-8 justify-content-center" >
                                <div class="col-lg-4">
                                    <h5 class="mb-1">
                                        {{requirement.title}} <span v-if="!requirement.required"  class="text-muted small">(Optional)</span>
                                    </h5>
                                    <small>{{requirement.description}}</small>
                                </div>
                                <div class="col-lg-8">
                                    <select v-model="data[requirement.id]" class="form-select"
                                        :name="`${requirement.id}_pro_select`">
                                        <option :value="undefined">--Select {{requirement.name}}--</option>
                                        <option v-for="(item, iIndex) in requirement.values"
                                        :key="iIndex" :value="item.value">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="requirement.type == 'pro_radio'" :id="`iReqT${requirement.id}`"
                                class="row pro_radio gy-2 mb-5 mb-md-8 justify-content-center" >
                                <div class="col-lg-4">
                                    <h5 class="mb-1">
                                        {{requirement.title}} <span v-if="!requirement.required"  class="text-muted">(Optional)</span>
                                    </h5>
                                    <p class="text-muted mb-2">{{requirement.description}}</p>
                                </div>
                                <div class="col-lg-8">
                                    <div v-for="(rItem, rIndex) in requirement.values" :key="rIndex" class="form-check">
                                        <input v-model="data[requirement.id]" class="form-check-input"
                                            type="radio" :name="`${requirement.id}_pro_radio`" :value="rItem.value"
                                            :id="`${requirement.id}_${rItem.id}_pro_radio`">
                                        <label class="form-check-label" :for="`${requirement.id}_${rItem.id}_pro_radio`">
                                            {{rItem.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="requirement.type == 'pro_checkbox'" :id="`iReqT${requirement.id}`"
                                class="row pro_checkbox gy-2 mb-3 mb-lg-5 justify-content-center" >
                                <div class="col-lg-4">
                                    <h5 class="mb-1">
                                        {{requirement.title}} <span v-if="!requirement.required"  class="text-muted">(Optional)</span>
                                    </h5>
                                    <p class="text-muted mb-2">{{requirement.description}}</p>
                                </div>
                                <div class="col-lg-8">
                                    <div v-for="(chItem, chIndex) in requirement.values" :key="chIndex" class="form-check form-check-inline">
                                        <input @change="addSelection(chItem, requirement.multiple)" class="form-check-input"
                                            type="checkbox" :name="`${requirement.id}_${chItem.id}_pro_checkbox`" 
                                            :checked="acceptedValues.includes(chItem.id)" :value="chItem.value"
                                            :id="`${requirement.id}_${chItem.id}_pro_checkbox`">
                                        <label class="form-check-label" :for="`${requirement.id}_${chItem.id}_pro_checkbox`">
                                            {{chItem.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="requirement.type == 'color_gallery'" :id="`iReqT${requirement.id}`"
                                class="row color_gallery gy-2 mb-5 mb-md-8 justify-content-center" >
                                <div class="col-lg-12 text-center">   
                                    <h5 class="mb-2">
                                        {{requirement.title}} <span v-if="!requirement.required"  class="text-muted small">(Optional)</span>
                                    </h5>
                                    <small>{{requirement.description}}</small>
                                </div>
                                <div class="col-12 text-center">
                                    <div class="row g-3">
                                        <div v-for="(colorType, clIndex) in requirement.values" :key="clIndex"
                                        class="col-md-4 col-6 cursor-pointer">
                                            <color-gallery-card 
                                            @click.native="addSelection(colorType, requirement.multiple)"
                                            :colorType="colorType"
                                            :selected="acceptedValues.includes(colorType.id)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="requirement.type == 'photo_gallery'" :id="`iReqT${requirement.id}`"
                                class="row photo_gallery gy-2 mb-5 mb-md-8 justify-content-center">
                                <div class="col-lg-12 text-center">  
                                    <h5 class="mb-2">
                                        {{requirement.title}}
                                    </h5>
                                    <small>{{requirement.description}}</small>
                                </div>
                                <div class="col-12 text-center">
                                    <div class="row g-3">
                                        <div v-for="(photoType, ptIndex) in requirement.values" :key="ptIndex"
                                        class="col-md-4 col-6 cursor-pointer">
                                            <photo-gallery-card
                                                @click.native="addSelection(photoType, requirement.multiple)"
                                                :photoType="photoType"
                                                :selected="acceptedValues.includes(photoType.id)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="requirement.type == 'file_uploader'" :id="`iReqT${requirement.id}`"
                                class="row file_uploader gy-2 mb-5 mb-md-8 justify-content-center">
                                <div class="col-lg-12">
                                    <h6 class="text-center">{{requirement.name}} <span v-if="!requirement.required"  class="text-muted small">(Optional)</span></h6>
                                    <vue-dropzone :id="`inputFileDrop${requirement.id}`" :ref="`inputFileDrop${requirement.id}`"
                                        :use-custom-slot="true" :maxFiles="requirement.multiple ? 10 : 1">
                                        <div class="needsclick m-0">
                                            <div><span class="bx bx-cloud-upload fs-1"></span></div>
                                            <h6 class="text-center">{{requirement.title}}</h6>
                                            <small>{{requirement.description}}</small>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center col-lg-8">
                        <button @click.prevent="evaluateData()" class="btn btn-lg btn-primary"> Start Order Now </button>
                    </div>
                </div>
            </div>
            <div v-else class="text-center"> 
                <h5>No Requirement for this task</h5>
                <p>Please this task settings probably not ready.</p>
                <router-link to="/tasks" class="btn btn-primary"> Try anothoer task </router-link>
            </div>
        </div>
        <is-loading v-if="isLoading" />
    </div>
</template>

<script>
import ColorGalleryCard from "@/components/cards/ColorGalleryCard.vue";
import PhotoGalleryCard from "@/components/cards/PhotoGalleryCard.vue";
import VueDropzone from "@/components/VueDropzone.vue";
import IsLoading from '@/components/IsLoading.vue';

export default {
    name: 'task-requirements',
    components:{
        ColorGalleryCard,
        PhotoGalleryCard,
        VueDropzone,
        IsLoading
    },      
    data() {
        return {
            isLoading: true,
            data:{},
            task:{},
            acceptedValues:[],
        };
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        requirements(){
            return this.task.requirements
        },
        requiredKeys(){
            return this.requirements.filter(r => r.required).map(i => {
                return {
                    id: i.id,
                    required: i.required,
                    required_text: i.required_text,
                    type: i.type,
                }
            })
        },
        fileUploads(){
            return this.requirements.filter(r => r.type == 'file_uploader')
                .map(i => {
                    return {id: i.id, type: i.type, multiple: i.multiple }
                })
        },
        formatTask(){
            var allowed = ['id','title', 'slug','image','thumb_path',
                            ,'is_active', 'type', 'delivery_time']
            return Object.keys(this.task)
                    .filter(key => allowed.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.task[key];
                        return obj;
                    }, {});
        },
        order(){
           return this.$store.state.order
        },
    },
    methods:{
        async evaluateData(){
            for (const item of this.requiredKeys) {
                if(item.type == "file_uploader"){
                    if(!this.collectFiles().find(i => i.id == item.id)){
                        this.locateError(item); return
                    }
                }else if(!this.data[item.id]){
                    this.locateError(item); return
                }
                if(Array.isArray(this.data[item.id]) && !this.data[item.id].length){
                    this.locateError(item); return
                }
            }
            this.submitToCart()
        },
        submitToCart(data){
            const formData = new FormData();
            formData.append("data", JSON.stringify(this.preparedData()));
            if(this.collectFiles().length){
                this.collectFiles().forEach((item,index) => formData.append(`ref_file+${item.id}_${index}`, item.file))
            }
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post("/orders/create", formData)
                .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    // this.$store.commit('SET_ORDER', response.data.data.order);
                    this.$router.push({path: `/orders/${response.data.data.id}`})
                }
            })
        },
        locateError(item){
            this.scrollToView(`iReqT${item.id}`);
            this.alertError(item.required_text)
        },
        addSelection(item, multiple = false){
            if(!this.data[item.requirement_id]){ this.data[item.requirement_id] = []}
            if(multiple){
                if(this.data[item.requirement_id].map((i) => i.id).includes(item.id)){
                    this.data[item.requirement_id].splice(this.data[item.requirement_id].indexOf(item), 1);
                }else{
                   this.data[item.requirement_id].unshift(item)              
                }
                if(this.data[item.requirement_id].length > 4){
                    this.data[item.requirement_id].pop();
                }
            }else{
                this.data[item.requirement_id] = [item]
            }
            this.updateSelection()
        },
        updateSelection(){
            this.acceptedValues = []
            for (const item of Object.values(this.data)){
                if(Array.isArray(item)){
                    this.acceptedValues.push(...item.map(i => i.id));
                }
            }
        },
        preparedData(){
            let data = { task_id: this.$route.query.task_id }
            data.requirements = this.requirements.map((req) => {
                return { 
                    id: req.id, 
                    type: req.type, 
                    name: req.name, 
                    multiple: req.multiple, 
                    title: req.title,
                    answer: this.data[req.id] ? this.data[req.id] : null 
                }
            });
            return data
        },
        collectFiles(){
            var arrafile = []
            this.fileUploads.forEach(np => {
                if(this.$refs[`inputFileDrop${np.id}`]){
                    var drops = this.$refs[`inputFileDrop${np.id}`][0].getAcceptedFiles()
                    drops.forEach(dp => arrafile.push({id: np.id, file: dp}));
                }
            })
            return arrafile
        },
    },
    created(){
        this.$http.get(`/tasks/${this.$route.query.task_id}/show`)
        .then((response) => {
            if(response.data.success){
                this.task = response.data.data
                this.isLoading = false
            }else{
                this.$router.push({path: '/404-error'})
            }
        })
    },
}
</script>

<style scoped>
.project-launch .form-control:not(textarea) {
    height: 52px;
}
.project-launch .form-select,
.project-launch .form-control {
    border-width: 2px !important;
    font-size: 18px;
}
</style>
